import React from 'react';

import SearchDropdown from 'components/SearchDropdown';
import { DropdownTriggerConditionalProps, InstanceProps, VariantConditionalProps } from 'components/SearchDropdown/structures';
import { SEARCH_PATHS } from 'shared/constants';

import SEARCH_MANY_CUSTOMERS from './SearchManyCustomers.graphql';
import { SearchManyCustomers, SearchManyCustomersVariables } from './__types/SearchManyCustomers';

const SelectCustomer = (
  props: InstanceProps<SearchManyCustomers, SearchManyCustomersVariables> &
    DropdownTriggerConditionalProps<SearchManyCustomers> &
    VariantConditionalProps<SearchManyCustomers>,
) => {
  const variables = { ...(props.variables || {}), paths: SEARCH_PATHS.CUSTOMER.defaultPaths };
  return (
    <SearchDropdown<SearchManyCustomers, Omit<SearchManyCustomersVariables, 'skip' | 'limit'>>
      query={SEARCH_MANY_CUSTOMERS}
      label="name"
      {...props}
      variables={variables}
    />
  );
};

export default SelectCustomer;
