import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export default function useDebounceState<T>(input: T, output: (data: T) => void, delay = 300) {
  const [data, setData] = useState(input);

  const dataRef = useRef(input);

  const callback = useRef(debounce(() => output(dataRef.current), delay));

  useEffect(() => {
    if (data) {
      dataRef.current = data;
    }
  }, [data]);

  return { callback: callback.current, data, setData, dataRef };
}

export function useDebouncedValue<T>(input: T, output: (data: T) => void, delay = 300) {
  const [data, setData] = useState(input);

  const dataRef = useRef(input);

  const callback = useRef(debounce(() => output(dataRef.current), delay));

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  return { callback: callback.current, data, setData, dataRef };
}
