import React from 'react';

import SearchDropdown from 'components/SearchDropdown';
import { DropdownTriggerConditionalProps, InstanceProps, VariantConditionalProps } from 'components/SearchDropdown/structures';
import { SEARCH_PATHS } from 'shared/constants';

import SEARCH_MANY_USERS from './SearchManyUsers.graphql';
import { SearchManyUsers, SearchManyUsersVariables } from './__types/SearchManyUsers';

const SelectUser = (
  props: InstanceProps<SearchManyUsers, SearchManyUsersVariables> &
    DropdownTriggerConditionalProps<SearchManyUsers> &
    VariantConditionalProps<SearchManyUsers>,
) => {
  const variables = { ...(props.variables || {}), paths: SEARCH_PATHS.USER.defaultPaths };
  return (
    <SearchDropdown<SearchManyUsers, Omit<SearchManyUsersVariables, 'skip' | 'limit'>>
      query={SEARCH_MANY_USERS}
      label="name"
      {...props}
      variables={variables}
    />
  );
};
export default SelectUser;
