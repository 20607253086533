import {
  NotificationFragment,
  NotificationFragment_BackChannelAuthRequestNotification_,
  NotificationFragment_EventNotification_,
  NotificationFragment_ExpiringContractNotification_,
  NotificationFragment_ExportableNotification_,
  NotificationFragment_MailerNotification_,
  NotificationFragment_MentionNotification_,
  NotificationFragment_SaleNotification_,
  NotificationFragment_SharingNotification_,
  NotificationFragment_TaskNotification_,
} from 'fragments/__types/NOTIFICATION';
import React from 'react';

import { NOTIFICATION_TYPE } from '__types/graphql';

import { BackChannelAuth } from './BackChannelAuth';
import EventReminder from './EventReminder';
import ExpiringContract from './ExpiringContract';
import Export from './Export';
import Mailer from './Mailer';
import MentionedInNote from './MentionedInNote';
import ResourceShared from './ResourceShared';
import TaskAssigned from './TaskAssigned';
import TaskAssignedExpiring from './TaskAssignedExpiring';
import WonSale from './WonSale';

interface Props {
  notification: NotificationFragment;
}

const Item = ({ notification }: Props) => {
  return {
    [NOTIFICATION_TYPE.WON_SALE]: <WonSale notification={notification as NotificationFragment_SaleNotification_} />,
    [NOTIFICATION_TYPE.EVENT_REMINDER]: <EventReminder notification={notification as NotificationFragment_EventNotification_} />,
    [NOTIFICATION_TYPE.EXPIRING_CONTRACT]: <ExpiringContract notification={notification as NotificationFragment_ExpiringContractNotification_} />,
    [NOTIFICATION_TYPE.TASK_ASSIGNED]: <TaskAssigned notification={notification as NotificationFragment_TaskNotification_} />,
    [NOTIFICATION_TYPE.TASK_ASSIGNED_EXPIRING]: <TaskAssignedExpiring notification={notification as NotificationFragment_TaskNotification_} />,
    [NOTIFICATION_TYPE.MENTIONED_IN_NOTE]: <MentionedInNote notification={notification as NotificationFragment_MentionNotification_} />,
    [NOTIFICATION_TYPE.EXPORT_EMPTY]: <Export notification={notification as NotificationFragment_ExportableNotification_} variant="empty" />,
    [NOTIFICATION_TYPE.EXPORT_COMPLETE]: <Export notification={notification as NotificationFragment_ExportableNotification_} variant="complete" />,
    [NOTIFICATION_TYPE.EXPORT_ERROR]: <Export notification={notification as NotificationFragment_ExportableNotification_} variant="error" />,
    [NOTIFICATION_TYPE.MAILER_FAILURE]: <Mailer notification={notification as NotificationFragment_MailerNotification_}></Mailer>,
    [NOTIFICATION_TYPE.LOGIN_REQUESTED]: <BackChannelAuth notification={notification as NotificationFragment_BackChannelAuthRequestNotification_} />,
    [NOTIFICATION_TYPE.RESOURCE_SHARED]: <ResourceShared notification={notification as NotificationFragment_SharingNotification_} />,
    [NOTIFICATION_TYPE.UNVERIFIED_DOMAIN]: null,
  }[notification.type];
};

export default Item;
