import React from 'react';

import SearchDropdown from 'components/SearchDropdown';
import { DropdownTriggerConditionalProps, InstanceProps, VariantConditionalProps } from 'components/SearchDropdown/structures';
import { SEARCH_PATHS } from 'shared/constants';

import SEARCH_PEOPLE from './SearchPeople.graphql';
import { SearchPeople, SearchPeopleVariables } from './__types/SearchPeople';

const SelectPerson = (
  props: InstanceProps<SearchPeople, SearchPeopleVariables> & DropdownTriggerConditionalProps<SearchPeople> & VariantConditionalProps<SearchPeople>,
) => {
  const variables = { ...(props.variables || {}), paths: SEARCH_PATHS.PERSON.defaultPaths };
  return (
    <SearchDropdown<SearchPeople, Omit<SearchPeopleVariables, 'skip' | 'limit'>> query={SEARCH_PEOPLE} label="name" {...props} variables={variables} />
  );
};
export default SelectPerson;
